import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  Form,
  Select,
  Input,
  Radio,
  DatePicker,
  Spin,
} from "antd";
import moment from "moment";
import ApiCalls from "../../../apis/ApiCalls";

const StyledCol = styled(Col)`
  .ant-radio-button-wrapper {
    width: 200px;
    display: flex;
    justify-content: center;
    /* height: 50px; */
    align-items: center;
    /* margin: 12px */
    border-radius: 5px;
    height: 57px;
    text-transform: capitalize;
  }
  .ant-radio-group {
    display: flex;
    width: 100%;
  }
  .ant-radio-button-wrapper-checked {
    color: "#000000";
    height: 57px;
  }
  .ant-picker {
    width: 100%;
    border-radius: 5px;
    padding: 15.5px;
    text-transform: capitalize;
  }
  .ant-btn {
    border-radius: 25px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    width: 170px;
    height: 40px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: #ffffff;
  }

  .ant-form-item-label > label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6b6b6b;
    padding-left: 15px;
    text-transform: capitalize;
  }

  .ant-select-multiple .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    padding: 10.5px;
    text-transform: capitalize;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
  }

  .ant-input-lg {
    padding: 15.5px 15px;
    font-size: 16px;
    color: #2f2f2f;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 5px;
`;

const ProfileInfo = ({ handleChange, acceptConditions, ...props }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      {props.action === "accept" && (
        <Spin spinning={loading}>
          <Form layout="vertical">
            <Row gutter={24}>
              <StyledCol
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 10 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  name="taxPercentage"
                  label="Tax percentage*"
                  rules={[
                    {
                      pattern: new RegExp("^[1-9]?[0-9]{1}$|^100$"),
                      message: "Please use numbers within 1 and 100",
                    },
                  ]}
                  hasFeedback
                >
                  <StyledInput
                    placeholder="Tax percentage"
                    size="large"
                    name="taxPercentage"
                    onChange={(event) => {
                      handleChange(event.target.name, event.target.value);
                    }}
                  />
                </Form.Item>
              </StyledCol>
            </Row>
            <Row gutter={24}>
              <StyledCol
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item label="Platform amount type*">
                  <Radio.Group
                    name="platformAmountType"
                    default="fixed"
                    size="large"
                    onChange={(event) => {
                      handleChange(event.target.name, event.target.value);
                    }}
                  >
                    <Radio.Button name="platformAmountType" value="fixed">
                      Fixed
                    </Radio.Button>
                    <Radio.Button name="platformAmountType" value="percentage">
                      Percentage
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </StyledCol>
            </Row>
            <Row gutter={24}>
              <StyledCol
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 10 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  label="Platform amount*"
                  name="platformAmount"
                  rules={[
                    {
                      pattern: new RegExp("^(0|[1-9][0-9]*)$"),
                      message: "Please use numbers",
                    },
                  ]}
                  hasFeedback
                >
                  <StyledInput
                    name="platformAmount"
                    placeholder="Platform amount"
                    size="large"
                    style={{ textTransform: "lowercase" }}
                    onChange={(event) => {
                      handleChange(event.target.name, event.target.value);
                    }}
                  />
                </Form.Item>
              </StyledCol>
            </Row>
            <Row gutter={24}></Row>
          </Form>
        </Spin>
      )}

      {props.action === "reject" && (
        <Spin spinning={loading}>
          <Form layout="vertical">
            <Row gutter={24}>
              <StyledCol
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 10 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  label="Reason for rejection*"
                  name="rejectReason"
                  rules={[
                    { required: true, message: "Reject reason is required" },
                  ]}
                  hasFeedback
                >
                  <StyledInput
                    name="rejectReason"
                    placeholder="Reason for rejection"
                    size="large"
                    onChange={(event) => {
                      handleChange(event.target.name, event.target.value);
                    }}
                  />
                </Form.Item>
              </StyledCol>
            </Row>
          </Form>
        </Spin>
      )}
    </>
  );
};

export default ProfileInfo;
